.Select {
  position: relative;
  color: var(--light);
  display: flex;
}

.Select__select {
  background-color: var(--dark-semi);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 1rem;
  padding-right: 2.5rem;
  outline: none;
  border: 0.2rem solid var(--light-semi);
  border-radius: 0.5rem;
  font-size: inherit;
  color: inherit;
  width: 100%;
  height: 4.4rem;
  font-family: inherit;
  text-transform: uppercase;
  cursor: pointer;
  transition: border 0.2s;
}

.Select__select:hover {
  border-color: var(--light);
}

.Select__icon {
  position: absolute;
  fill: currentColor;
  width: 1.1rem;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}