@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&family=Saira+Condensed:wght@600&display=swap);
html {
  box-sizing: border-box;
  font-size: 62.5%;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --dark: #000;
  --light: #fff;
  --dark-alpha: rgba(0, 0, 0, 0.8);
  --light-alpha: rgba(255, 255, 255, 0.8);
  --dark-semi: #222222;
  --light-semi: #6d6d6d;
  --accent: #0069ed;
}

body {
  margin: 0;
  font-size: 2rem;
  font-family: 'Saira Condensed', sans-serif;
  line-height: 1;
  text-transform: uppercase;
  font-weight: normal;
}

button {
  border: 0.2rem solid #6d6d6d;
  border: 0.2rem solid var(--light-semi);
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0;
  text-decoration: none;
  background-color: #222222;
  background-color: var(--dark-semi);
  color: #fff;
  color: var(--light);
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0.5rem;
  font-size: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.4rem;
  font-family: inherit;
  text-transform: uppercase;
  transition: border 0.2s;
}

button > * + * {
  margin-left: 1rem;
}

button > svg {
  fill: currentColor;
}

button:hover {
  border-color: #fff;
  border-color: var(--light);
}

*:focus-visible {
  box-shadow: 0 0 0 0.2rem #0069ed;
  box-shadow: 0 0 0 0.2rem var(--accent);
  outline: 0.2rem solid transparent;
}

*:focus {
  box-shadow: 0 0 0 0.2rem #0069ed;
  box-shadow: 0 0 0 0.2rem var(--accent);
  outline: 0.2rem solid transparent;
}

*:focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media only screen and (min-width: 992px) {
  body {
    font-size: 2.4rem;
  }
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.App__main {
  flex-grow: 1;
  display: flex;
  justify-content: stretch;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.App__actions {
  background-color: var(--dark-semi);
  padding: 1rem 2rem;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
}

.App__actions > * + * {
  margin-left: 1rem;
}

@media only screen and (min-width: 768px) {
  .App__actions {
    order: -1;
  }
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 5rem 2rem;
  width: 100%;
}

.Card > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.Card__content {
  color: var(--light);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Card__content > * + * {
  margin-top: 1.5rem;
}

.Card__title {
  margin: 0;
  text-shadow: 0rem 0rem 1rem var(--dark);
  font-size: 3.7rem;
  font-family: 'Archivo Black', sans-serif;
  font-weight: normal;
}

.Card__table {
  background-color: var(--dark-alpha);
  border-collapse: collapse;
}

.Card__table th,
.Card__table td {
  min-width: 5rem;
  border: 0.2rem solid var(--light-semi);
  padding: 0.5rem;
}

.Card__table td {
  font-size: 2.4rem;
}

.Card__bubble {
  background: var(--light-alpha);
  padding: 1rem 2rem;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.Card__bubble > * {
  white-space: nowrap;
  display: inline-flex;
}

@media only screen and (min-width: 768px) {
  .Card__bubble {
    bottom: 2rem;
    width: auto;
    border-radius: 5rem;
  }
}

@media only screen and (min-width: 992px) {
  .Card__title {
    font-size: 6rem;
  }

  .Card__table th,
  .Card__table td {
    min-width: 8rem;
  }

  .Card__table td {
    font-size: 3.8rem;
  }
}

.Loader::before {
  content: '';
  display: block;
  border: 1rem solid var(--light);
  border-top: 1rem solid var(--accent);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation: spin 1s linear infinite;
}

.Loader--inverse::before {
  border: 1rem solid var(--accent);
  border-top: 1rem solid var(--light);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Stats {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.Stats__item {
  display: flex;
  position: relative;
}

.Stats__item:not(:first-child)::before {
  content: '';
  left: 0;
  width: 100%;
  border-top: 0.4rem solid var(--dark-semi);
  position: absolute;
  top: -0.2rem;
}

@media only screen and (min-width: 768px) {
  .Stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .Stats__item:not(:first-child)::before {
    top: 0;
    height: 100%;
    border-left: 0.4rem solid var(--dark-semi);
    border-top: 0;
    left: -0.2rem;
  }
}

.Comments {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}

.Comments > * {
  width: 100%;
}

.Comments__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  z-index: -1;
}

.Comments__content iframe {
  background-color: var(--light);
  width: 100% !important;
  visibility: inherit !important;
}

@media only screen and (min-width: 768px)  {
  .Comments {
    max-width: 76.8rem;
    margin: auto;
  }
}
.Popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--light);
  transform: translateY(100%);
  transition: transform 0.2s, visibility 0s linear 0.2s;
  display: flex;
  flex-direction: column;
  visibility: hidden;
}

.Popup.active {
  transform: translateY(0);
  visibility: visible;
  transition: transform 0.2s, visibility 0s;
}

.Popup__header {
  padding: 1rem 2rem;
  background-color: var(--dark-semi);
  z-index: 1;
  justify-content: flex-end;
  display: flex;
  flex-shrink: 0;
  order: 2;
}

.Popup__content {
  padding: 1.5rem 2rem;
  flex-grow: 1;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (min-width: 768px)  {
  .Popup {
    transform: translateY(-100%);
  }

  .Popup__header {
    order: 0;
  }
}

.Select {
  position: relative;
  color: var(--light);
  display: flex;
}

.Select__select {
  background-color: var(--dark-semi);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 1rem;
  padding-right: 2.5rem;
  outline: none;
  border: 0.2rem solid var(--light-semi);
  border-radius: 0.5rem;
  font-size: inherit;
  color: inherit;
  width: 100%;
  height: 4.4rem;
  font-family: inherit;
  text-transform: uppercase;
  cursor: pointer;
  transition: border 0.2s;
}

.Select__select:hover {
  border-color: var(--light);
}

.Select__icon {
  position: absolute;
  fill: currentColor;
  width: 1.1rem;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
