.Stats {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.Stats__item {
  display: flex;
  position: relative;
}

.Stats__item:not(:first-child)::before {
  content: '';
  left: 0;
  width: 100%;
  border-top: 0.4rem solid var(--dark-semi);
  position: absolute;
  top: -0.2rem;
}

@media only screen and (min-width: 768px) {
  .Stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .Stats__item:not(:first-child)::before {
    top: 0;
    height: 100%;
    border-left: 0.4rem solid var(--dark-semi);
    border-top: 0;
    left: -0.2rem;
  }
}
