.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 5rem 2rem;
  width: 100%;
}

.Card > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.Card__content {
  color: var(--light);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Card__content > * + * {
  margin-top: 1.5rem;
}

.Card__title {
  margin: 0;
  text-shadow: 0rem 0rem 1rem var(--dark);
  font-size: 3.7rem;
  font-family: 'Archivo Black', sans-serif;
  font-weight: normal;
}

.Card__table {
  background-color: var(--dark-alpha);
  border-collapse: collapse;
}

.Card__table th,
.Card__table td {
  min-width: 5rem;
  border: 0.2rem solid var(--light-semi);
  padding: 0.5rem;
}

.Card__table td {
  font-size: 2.4rem;
}

.Card__bubble {
  background: var(--light-alpha);
  padding: 1rem 2rem;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.Card__bubble > * {
  white-space: nowrap;
  display: inline-flex;
}

@media only screen and (min-width: 768px) {
  .Card__bubble {
    bottom: 2rem;
    width: auto;
    border-radius: 5rem;
  }
}

@media only screen and (min-width: 992px) {
  .Card__title {
    font-size: 6rem;
  }

  .Card__table th,
  .Card__table td {
    min-width: 8rem;
  }

  .Card__table td {
    font-size: 3.8rem;
  }
}
